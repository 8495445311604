ACC.cardmanagement = {

	_autoload: [
		"bindCardDownloadPopup",
		"bindCardManagementPopupLink",
		"bindSaveSelectedCardIds",
		"bindCheckboxHelper",
		"bindCardActivation",
		"initStorageHelper",
		"loadEmailDeliveryStatus",
		"bindCardResend",
		"bindCardSearch",
		"bindPopupNavigation",
		"bindSingleCardActivationLink",
		"getActivationRequests",
		"addAllOrderEntryCardIdsToActivationRequest",
		"removeOldActivationRequests",
		"disableGiftCardEntries",
		"bindRemoveAllCard",
		"bindSubmitRemoveAllCard"
	],

	activationRequests: [],

	SessionStorageHelper: null,

	/**
	 * item format: {timestamp, cardId, email}
	 */
	resendStorage: [],

	// timeout for activationRequests and resendStatus in seconds (default value should be replaced with b2b2gk config value)
	maxDelay: 240,

	/**
	 * className to identify this popup
	 * can be used in selectors to precisely select content of this popup
	 */
	popupClassName: "card-management-overlay",

	/**
	 *
	 */
	selectors: {
		popupContent: ".card-management-overlay #cboxLoadedContent",
		popupPaginationLinks: ".card-management-overlay .pagination a",
		popupSortForms: ".card-management-overlay .sort-refine-bar form",
		popupFacetForms: ".card-management-overlay .js-facet-values form",
		popupFacetLinks: ".card-management-overlay .js-facet-values a",
		popupCardActivationButton: ".card-management-overlay .js-activate-cards[data-checkbox-ref]",
		popupAllCardActivationButton: ".card-management-overlay .js-activate-all-cards",
		cardActivationForm: ".card-activation-form",
		cardSearchInput: ".js-card-search-input",
		singleCardActivationLink: ".js-single-card-activation-link",
		cardActivationSingleCardForm: ".card-activation-single-card-form",
		cardResendActionToggle: ".js-single-card-resend-link",
		cardResendAction: ".js-single-card-resend-action",
		cardResendEmail: ".js-single-card-resend-email",
		cardResendStatus: ".js-single-card-resend-status"
	},

	initStorageHelper: function () {
		this.SessionStorageHelper = new ACC.global.StorageHelperModel("sessionStorage");
	},

	/**
	 * common AJAX success handler to show a response in the popup
	 * @param data
	 * @param textStatus
	 * @param jqXHR
	 */
	popupDoneCallback: function (data, textStatus, jqXHR) {
		$(ACC.cardmanagement.selectors.popupContent).empty().append(data);
		ACC.cardmanagement.removeOldActivationRequests();
		ACC.cardmanagement.addAllOrderEntryCardIdsToActivationRequest();
		ACC.cardmanagement.disableGiftCardEntries();
		ACC.cardmanagement.updateEmailDeliveryStatusView();
		ACC.colorbox.resize();
	},

	/**
	 * common AJAX error handler
	 * @param jqXHR
	 * @param textStatus
	 * @param errorThrown
	 */
	popupFailCallback: function (jqXHR, textStatus, errorThrown) {
		$(ACC.cardmanagement.selectors.popupContent).empty().append(errorThrown);
		ACC.colorbox.resize();
		console.error(textStatus + ":" + errorThrown);
	},

	/**
	 * bind events for un-/selecting checkboxes
	 * "data-checkbox-ref" attribute should contain a precise-enough selector for the checkbox-collection
	 */
	bindCheckboxHelper: function () {
		$(document).on("click.checkboxHelper", ".js-check-all[data-checkbox-ref]", function (event) {
			event.preventDefault();
			var checkboxSelector = $(event.currentTarget).data("checkboxRef");
			$(checkboxSelector).prop("checked", true);
		});

		$(document).on("click.checkboxHelper", ".js-check-none[data-checkbox-ref]", function (event) {
			event.preventDefault();
			var checkboxSelector = $(event.currentTarget).data("checkboxRef");
			$(checkboxSelector).prop("checked", false);
		});
		$(document).on("click.checkboxHelper", ".js-check-invert[data-checkbox-ref]", function (event) {
			event.preventDefault();
			var checkboxSelector = $(event.currentTarget).data("checkboxRef");
			$(checkboxSelector).each(function (index, checkbox) {
				var $checkbox = $(checkbox);
				$checkbox.prop("checked", !$checkbox.prop("checked"));
			});
		});
	},

	//ueacs
	bindRemoveAllCard: function () {
		$(document).on('click.submit', 'a[data-submit-form-id], button[data-submit-form-id]', function (e) {
			var $removeAllCardValue = $('.js-popup-remove-all-card-value');
			var title = $removeAllCardValue.data('title');
			var content = $('#removeAllBasketButton').html();

			ACC.colorbox.open(title, {
				html: content,
				width: "400px",
				height:"200px"
			});
		});
	},

	bindSubmitRemoveAllCard: function () {
		$(document).on('click.submit', '#removeAllBasket', function (e) {
			$('#removeAllCartForm').submit();
		});
	},

	unbindCheckboxHelper: function () {
		$(document).off(".checkboxHelper");
	},

	bindSaveSelectedCardIds: function () {
		$(document).on("click.cardManagementPopup", ".card-activation-checkbox", function (event) {
			var dataCardId = $(this).val();
			var orderCode = $(this).attr("ordercode");
			var orderEntry = $(this).attr("orderentry");
			var url = ACC.config.encodedContextPath + '/my-account/saveSelectedCardId?dataCardId=' + dataCardId + '&orderCode=' + orderCode + '&orderEntry=' + orderEntry+ '&event=' + this.checked;
			$.get(url).done(function (data) {
				ACC.colorbox.open(title, {
					width: "960px",
					className: ACC.cardmanagement.popupClassName,
					html: data,
					onComplete: function () {
						ACC.cardmanagement.removeOldActivationRequests();
						ACC.cardmanagement.disableGiftCardEntries();
						ACC.cardmanagement.updateEmailDeliveryStatusView();
						ACC.colorbox.resize();
					}
				});
			});
		});
	},

	/**
	 * bind event to open the popup
	 */
	bindCardManagementPopupLink: function () {
		$(document).on("click.cardManagementPopup", ".js-card-management-overlay", function (event) {
			event.preventDefault();
			var $link = $(event.currentTarget),
				orderCode = $link.data("orderCode"),
				orderEntryNumber = $link.data("orderentryNumber"),
				title = $link.data("title"),
				url = ACC.config.encodedContextPath + '/my-account/card-administration?orderCode=' + orderCode + '&orderEntryNumber=' + orderEntryNumber;
			$.get(url).done(function (data) {
				ACC.colorbox.open(title, {
					width: "960px",
					className: ACC.cardmanagement.popupClassName,
					html: data,
					onComplete: function () {
						ACC.cardmanagement.removeOldActivationRequests();
						ACC.cardmanagement.disableGiftCardEntries();
						ACC.cardmanagement.updateEmailDeliveryStatusView();
						ACC.colorbox.resize();
					}
				});
			});
		});
	},

	unbindCardManagementPopupLink: function () {
		$(document).off(".cardManagementPopup");
	},

	/**
	 * bind events for card download
	 */
	bindCardDownloadPopup: function () {
		// handler to open the popup
		$(document).on("click.cardDownloadPopup", ".js-card-download-overlay", function (event) {
			event.preventDefault();
			var $link = $(event.currentTarget),
				title = $link.attr("title");
			ACC.colorbox.open(title, {
				width: "350px",
				className: "card-download-overlay",
				inline: true,
				href: $link.attr("href")
			});
		});
		// auto-show popup on pageload
		$('.js-card-download-overlay.js-popup-showonpageload').click();
		// handler to close popup on form submit
		$(document).on("submit.cardDownloadPopup", ".card-download-overlay form", function (event) {
			ACC.colorbox.close();
		});
	},

	unbindCardDownloadPopup: function () {
		$(document).off(".cardDownloadPopup");
	},

	bindCardSearch: function () {
		// focus and select on load
		$(this.selectors.cardSearchInput).select();
	},

	unbindCardSearch: function () {
	},

	/**
	 * show/hide resend-form-elements vs. status- and email-text
	 * @param $row
	 */
	toggleResendDisplay: function ($row) {
		$row.find(this.selectors.cardResendAction)
			.add($row.find(this.selectors.cardResendStatus))
			.add($row.find(this.selectors.cardResendEmail))
			.toggle();
	},

	/**
	 * load storage from the browser into temp storage and update view
	 */
	loadEmailDeliveryStatus: function () {
		this.resendStorage = JSON.parse(this.SessionStorageHelper.getItem("resendStorage")) || [];
		this.updateEmailDeliveryStatusView();
	},

	/**
	 * store temp storage in the browser
	 */
	storeEmailDeliveryStatus: function () {
		this.removeOldEmailDeliveryStatus();
		this.SessionStorageHelper.setItem("resendStorage", JSON.stringify(this.resendStorage));
	},

	/**
	 * remove outdated items from temp storage
	 */
	removeOldEmailDeliveryStatus: function () {
		var self = this;
		this.resendStorage = this.resendStorage.filter(function (item) {
			return self.shouldStillIndicateStatusTransition(item.timestamp);
		});
	},

	/**
	 * add item to temp storage and store it immediately
	 * @param cardId
	 * @param email
	 */
	addEmailDeliveryStatus: function (cardId, email) {
		this.resendStorage.push({
			timestamp: Date.now(),
			cardId: cardId,
			email: email
		});
		this.storeEmailDeliveryStatus();
	},

	/**
	 * change appearance for stored items
	 */
	updateEmailDeliveryStatusView: function () {
		var self = this;
		var resendStatusHtml = $('table[data-resend-status-html]').data('resendStatusHtml');
		this.removeOldEmailDeliveryStatus();
		// for each stored resendItem, where we can find rows by cardId:
		// - remove the resend link
		// - set stored email and different status text
		$.each(this.resendStorage, function (index, resendItem) {
			var $row = $("tr[data-card-id='" + resendItem.cardId + "']");
			$row.find(self.selectors.cardResendActionToggle).remove();
			$row.find(self.selectors.cardResendEmail).text(resendItem.email);
			$row.find(self.selectors.cardResendStatus).html(resendStatusHtml);
		});
	},

	/**
	 * bind events for card delivery resend actions
	 */
	bindCardResend: function () {
		var self = this;
		var emailAddressRegExp = /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,4}$/;
		// bind resend action activation link
		$(document).on("click.cardResend", this.selectors.cardResendActionToggle, function (event) {
			event.preventDefault();
			var $row = $(event.currentTarget).closest('tr[data-card-id]');
			self.toggleResendDisplay($row);
		});
		// bind resend form action
		$(document).on("click.cardResend", "button" + this.selectors.cardResendAction, function (event) {
			event.preventDefault();
			var $button = $(event.currentTarget);
			var $row = $button.closest('tr[data-card-id]');
			var $input = $row.find("input" + self.selectors.cardResendAction + ":visible");
			var newRecipientEmail = $input.val().trim();
			var cardId = $row.data("cardId");
			var resendUrl = $button.data("resendUrl");
			// validate Email address
			if (!newRecipientEmail.match(emailAddressRegExp)) {
				var errorMessageEmailInvalid = $('table[data-resend-error-message-email-invalid]').data('resendErrorMessageEmailInvalid');
				ACC.global.showErrorMsgTooltip($input, errorMessageEmailInvalid);
				return;
			}
			var postData = {
				newRecipientEmail: newRecipientEmail,
				CSRFToken: ACC.config.CSRFToken
			};
			// start AJAX
			ACC.loading.blockUI();
			$.ajax({
				url: resendUrl,
				method: "POST",
				data: postData
			})
			.done(function(data, textStatus, jqXHR){
				// check, if request was redirected and response body contains a specific part of the login page (e.g. in case of session timeout)
				if (data.match(/id="loginForm"/)) {
					window.location.reload();
					return;
				}
				self.toggleResendDisplay($row);
				self.addEmailDeliveryStatus(cardId, newRecipientEmail);
				self.updateEmailDeliveryStatusView();
				self.storeEmailDeliveryStatus();
			})
			.fail(function(jqXHR, textStatus, errorThrown){
				self.toggleResendDisplay($row);
				console.error(jqXHR, textStatus, errorThrown);
			})
			.always(function(){
				ACC.loading.unBlockUI();
			});
		});
	},

	unbindCardResend: function () {
		$(document).off(".cardResend");
	},

	/**
	 * bind events to post the cardId(s) to BE
	 */
	bindCardActivation: function () {
		var self = this;

		/**
		 * prevent card-activation-form-submit if no cards are selected
		 */
		$(document).on("submit.cardActivation", this.selectors.cardActivationForm, function (event) {
			var $form = $(event.currentTarget),
				params = $form.serializeArray(),
				checkboxSelector = $form.data("checkboxRef"),
				$checkboxes = $(checkboxSelector),
				cardIdFilter = function (param) {
					return param.name === "cardIds"
				},
				noCardsSelected = params.filter(cardIdFilter).length === 0;
			if (noCardsSelected) {
				event.preventDefault();
			}
			else {
				// filter selected checkboxes, concat the values via map(), get the plain array and join the values
				var cardIds = $checkboxes
				.filter(":checked")
				.map(function (index, checkbox) {
					return $(checkbox).val();
				})
				.get()
				.join(",");

				self.updateActivationRequests(cardIds);
			}
		});

		/**
		 * collect selected checkboxes in popup, do AJAX request and replace popup content with response data
		 */
		$(document).on("click.cardActivation", this.selectors.popupCardActivationButton, function (event) {
			event.preventDefault();
			var $element = $(event.currentTarget),
				url = $element.data("targetUrl"),
				checkboxSelector = $element.data("checkboxRef"),
				$checkboxes = $(checkboxSelector),
				orderCode = $element.data("orderCode"),
				orderEntryNumber = $element.data("orderEntryNumber"),
				currentPage = $element.data('currentPage'),
				sort = $element.data('sort'),
				postData = {
					cardIds: "",
					orderCode: orderCode,
					orderEntryNumber: orderEntryNumber,
					page: currentPage,
					sort: sort
				};

			// filter selected checkboxes, concat the values via map(), get the plain array and join the values
			postData.cardIds = $checkboxes
			.filter(":checked")
			.map(function (index, checkbox) {
				return $(checkbox).val();
			})
			.get()
			.join(",");

			if (postData.cardIds.length) {
				self.updateActivationRequests(postData.cardIds);
				ACC.loading.blockUI();
				$.ajax({
					url: url,
					type: "POST",
					data: postData
				})
				.done(ACC.cardmanagement.popupDoneCallback)
				.fail(ACC.cardmanagement.popupFailCallback)
				.always(ACC.loading.unBlockUI);
			}
		});

		/**
		 * do AJAX request and replace popup content with response data
		 */
		$(document).on("click.cardActivation", this.selectors.popupAllCardActivationButton, function (event) {
			event.preventDefault();
			var $element = $(event.currentTarget),
				orderCode = $element.data("orderCode"),
				orderEntryNumber = $element.data("orderEntryNumber"),
				url = $element.data("targetUrl"),
				currentPage = $element.data('currentPage'),
				sort = $element.data('sort'),
				postData = {orderCode: orderCode, orderEntryNumber: orderEntryNumber, page: currentPage, sort: sort},
				confirmationText = $element.data("confirmationText");
			var $activateAllCardValue = $('.js-popup-activate-all-card-value');
			var title = $activateAllCardValue.data('title');
			var content = $('#activateAllCardButton').html();

			ACC.colorbox.open(title, {
				html: content,
				width: "400px",
				height:"200px"
			});

			$("#activateAllCardsCustom").click(function() {
				ACC.loading.blockUI();
				$.ajax({
					url: url,
					type: "POST",
					data: postData
				})
					.done(ACC.cardmanagement.popupDoneCallback)
					.fail(ACC.cardmanagement.popupFailCallback)
					.always(ACC.loading.unBlockUI);
					ACC.colorbox.close();
			});
			$("#cancelActivateAllCardButton").click(function () {
				ACC.colorbox.close();
			});
		});
	},

	unbindCardActivation: function () {
		$(document).off(".cardActivation");
	},

	/**
	 * bind event to intercept pagination, sorting and facet requests of the popup
	 * and show the response in the popup
	 */
	bindPopupNavigation: function () {
		// bind links click event interception
		$(document).on("click.popupNavigation", [this.selectors.popupPaginationLinks, this.selectors.popupFacetLinks].join(","), function (event) {
			event.preventDefault();
			var $link = $(event.currentTarget);
			$.get($link.attr("href"))
			.done(ACC.cardmanagement.popupDoneCallback)
			.fail(ACC.cardmanagement.popupFailCallback);
		});

		// bind forms submit event interception
		$(document).on("submit.popupNavigation", [this.selectors.popupSortForms, this.selectors.popupFacetForms].join(","), function (event) {
			event.preventDefault();
			var $form = $(event.currentTarget);
			$.ajax({
				url: $form.attr("action") || "",
				method: $form.attr("method") || "get",
				data: $form.serialize()
			})
			.done(ACC.cardmanagement.popupDoneCallback)
			.fail(ACC.cardmanagement.popupFailCallback);
		});
	},

	unbindPopupNavigation: function () {
		$(document).off(".popupNavigation");
	},

	bindSingleCardActivationLink: function () {
		var self = this;

		$(document).on("click.cardActivation", self.selectors.singleCardActivationLink, function (event) {
			event.preventDefault();

			var $link = $(event.currentTarget);
			var cardId = $link.data('cardid');
			var $singleCardActivationForm = $(self.selectors.cardActivationSingleCardForm);
			// set the cardId of the selected activation link
			$singleCardActivationForm.find('input[name=cardIds]').val(cardId);

			// update the activationRequests object
			self.updateActivationRequests(String(cardId));

			// submit the shadow form in order to activate the selected card
			$singleCardActivationForm.submit();
		});
	},

	getActivationRequests: function () {
		if (typeof(window.localStorage) !== "undefined") {
			var activationRequestsJson = localStorage.getItem("activationRequests");
			if (activationRequestsJson === null) {
				// no local records about gift cards in a status transition
				this.activationRequests = [];
			}
			else {
				this.activationRequests = JSON.parse(activationRequestsJson);
			}

			return this.activationRequests;
		}
	},

	/**
	 * add all order entry card ids after activateAllOrderEntryCards request. Data attribute "orderEntryCardIds" is used.
	 */
	addAllOrderEntryCardIdsToActivationRequest: function() {
		var $orderEntryCardIdsElement = $('[data-order-entry-card-ids]');
		if($orderEntryCardIdsElement.length) {
			var orderEntryCardIds = $orderEntryCardIdsElement.data('orderEntryCardIds');
			if(orderEntryCardIds.length){
				this.updateActivationRequests(orderEntryCardIds);
			}
		}
	},

	updateActivationRequests: function (cardIds) {
		this.removeOldActivationRequests();

		// quit function when cardIds is empty
		if(cardIds === '') {
			return false;
		}

		// create timestamp which determines until when we show the status transition for the given set of cards
		var timestamp = Math.floor(Date.now());
		// create an object for the new activation request
		var newActivationRequest = {"timestamp": timestamp, "cardIds": cardIds};
		this.activationRequests.push(newActivationRequest);

		// Put the new activation request into local storage
		if (typeof(window.localStorage) !== "undefined") {
			localStorage.setItem('activationRequests', JSON.stringify(this.activationRequests));
		}
	},

	removeOldActivationRequests: function () {
		var self = this;

		// quit when popup and card listing page aren't shown
		if($(self.selectors.popupContent).length === 0 && $(self.selectors.cardActivationForm).length === 0) {
			return false;
		}

		// check timestamps in activationRequests and remove the ones which are older than maxTime
		var tempActivationRequestsArray = [];
		$.each(self.activationRequests, function (index, activationRequest) {
			if (self.shouldStillIndicateStatusTransition(activationRequest.timestamp)) {
				tempActivationRequestsArray.push(activationRequest);
			}
		});

		self.activationRequests = tempActivationRequestsArray;

		// update activation request in local storage
		if (typeof(window.localStorage) !== "undefined") {
			localStorage.setItem('activationRequests', JSON.stringify(self.activationRequests));
		}
	},

	/**
	 * when a status transition is pending, then we don't show the "selection checkbox" for those gift cards.
	 */
	disableGiftCardEntries: function () {
		var self = this;

		// check if card overlay popup or card listing page is shown (default: popup)
		var $wrapper = $(self.selectors.popupContent);
		if ($(self.selectors.cardActivationForm).length) {
			$wrapper = $(self.selectors.cardActivationForm);
		}

		// go through all activationRequests and disable the checkboxes of the gift card entries
		$.each(self.activationRequests, function (index, activationRequest) {
			var cardIdsArray = [];
			// for single card
			if (activationRequest.cardIds.indexOf(',') === -1) {
				cardIdsArray = [activationRequest.cardIds];
			}
			else {
				cardIdsArray = activationRequest.cardIds.split(',');
			}

			$.each(cardIdsArray, function (i, value) {
				var $cardEntry = $wrapper.find('[data-card-id=' + value + ']');
				if($cardEntry.data('cardStatus') !== 'ACTIVE') {
					$cardEntry.find('input[type="checkbox"]').remove();
					$cardEntry.find('.js-card-status').text($cardEntry.data('msgStatusChange'));
				}
			});
		});
	},

	/**
	 * checks the difference of the current time and the given timestamp. If the difference is greater
	 * than the configured maxDelay => return false (remove the related activationRequest)
	 * @param timestamp
	 * @returns {boolean}
	 */
	shouldStillIndicateStatusTransition: function (timestamp) {
		var currentTimestamp = Math.floor(Date.now());

		// get maxDelay from b2bgkConfig, otherwise use default value
		var $statusChangeDelay = $('[data-status-change-delay]');
		if($statusChangeDelay.length) {
			this.maxDelay = $statusChangeDelay.data('statusChangeDelay');
		}

		var differenceInSec = (currentTimestamp - timestamp) / 1000;

		return this.maxDelay >= differenceInSec;
	}
};
